

let off = {
    s3: {
      REGION: "us-east-1",
      BUCKET: "imagenes-eplat"
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://dev.eplat.violetasw.digital"
    },
  
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_uzCmDM1uf",
      APP_CLIENT_ID: "1inbpbng1hu1e6hdrjabq6ik3",
      IDENTITY_POOL_ID: "us-east-1:46b9633f-a73d-4d4c-b917-721432cfd428"
    },
    MAX_ATTACHMENT_SIZE: 10000000
  };
  
  let dev = {
    s3: {
      REGION: "us-east-1",
      BUCKET: "imagenes-eplat"
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://dev.eplat.violetasw.digital"
    },
  
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_uzCmDM1uf",
      APP_CLIENT_ID: "1inbpbng1hu1e6hdrjabq6ik3",
      IDENTITY_POOL_ID: "us-east-1:46b9633f-a73d-4d4c-b917-721432cfd428"
    },
    MAX_ATTACHMENT_SIZE: 10000000,
    servipag: {
      baseApiUrl: "https://82vwf8qyx9.execute-api.us-east-2.amazonaws.com/dev"
    }
  };
  
  let qa = {
    s3: {
      REGION: "us-east-1",
      BUCKET: "imagenes-eplat"
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://qa.eplat.violetasw.digital"
    },
  
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_X9VPViOQO",
      APP_CLIENT_ID: "7tk2fpcsgsggiao8c0klavkeho",
      IDENTITY_POOL_ID: "us-east-1:4bc95a9c-f156-48cb-bd56-0da5fc8b8c54"
    },
    MAX_ATTACHMENT_SIZE: 10000000,
    servipag: {
      baseApiUrl: "https://82vwf8qyx9.execute-api.us-east-2.amazonaws.com/dev"
    }
  };
  
  let prod = {
    s3: {
      REGION: "us-east-1",
      BUCKET: "imagenes-eplat"
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://9h7vxiatn2.execute-api.us-east-1.amazonaws.com/prod"
    },
  
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_F9GP7IJM4",
      APP_CLIENT_ID: "4cc2s8cqqfop8d50i638a6g0mt",
      IDENTITY_POOL_ID: "us-east-1:9bddc7b3-fc2e-4922-8d0b-74beed61f4a2"
    },
    MAX_ATTACHMENT_SIZE: 10000000,
    servipag: {
      baseApiUrl: "https://g85ytxcfpd.execute-api.us-east-1.amazonaws.com/prod"
    }
  };
  
  let config = {
    ...(process.env.REACT_APP_STAGE === "prod" ? prod : ( process.env.REACT_APP_STAGE === "dev" ? dev : (process.env.REACT_APP_STAGE === "qa" ? qa:off))),
  };
  export default config;
  