import React, { useEffect } from 'react';
import './servipagButton.css';
function ServipagButton(props) {
    const { imagenServipag, test, baseApiUrl, dataPago, testData, ...rest } = props
    const [xml, setXml] = React.useState(null);
    const formServipag = React.useRef();
    
    useEffect(() => {
        console.log("xml", xml);

        if(xml !== undefined && xml !== null){
            formServipag.current.submit();
        }
    }, [xml]);

    async function xml1(event) {
        event.preventDefault();
        if (!test) {
            const settings = {
                method: 'POST',
                body: JSON.stringify(dataPago),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                cache: "no-cache",
                mode: "cors"
            };
            try {
                console.log("baseApiUrl: " + `${baseApiUrl}/iniciar_pago`);
                const fetchResponse = await fetch(`${baseApiUrl}/iniciar_pago`, settings);
                console.log("dataPago", JSON.stringify(dataPago));
                console.log("fetchResponse", JSON.stringify(fetchResponse));
                const data = (await fetchResponse.json()).data;
                setTimeout(() => {
                    setXml(data.xml1);
                });
            } catch (e) {
                console.log("error:" + e);
                throw e;
            }
        } else {
            setTimeout(() => {
                setXml(testData.xml);
                formServipag.current.submit();
            });
        }

    }

    return (
        <form method="post" action="https://www.servipag.com/BotonPago/BotonPago/Pagar" ref={formServipag} >
            <input type="hidden" defaultValue={xml} name="xml" />
            <button type="button" style={{ background: 'none', border: 'none', cursor: "pointer" }} onClick={xml1} >
                <img src={imagenServipag} {...rest} alt="Servipag" />
            </button>
        </form>

    )
}

export default ServipagButton;