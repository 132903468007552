import logo from './logo.svg';
import './App.css';
import { Amplify } from 'aws-amplify';
import config from './config';
import React, { useState, useEffect } from 'react';
import { AppContext } from "./AppContext";
import MainRoutes from "./MainRoutes";

Amplify.configure(
  {
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    Storage: {
      AWSS3:{
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        level : "protected",
        identityPoolId: config.cognito.IDENTITY_POOL_ID
      }
    },
    API: {
      endpoints: [
        {
          name: "ePlat",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        }
      ]
    }
  }
);


function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    communities: [],
    pisos: [],
    profile: "",
    selected: null
  });

  if ( isLoading == null  ) {}

  useEffect(() => {


    async function load() {

      // console.log("de load");

      setIsLoading(false);
    }

    load();
    // console.log("La data:", data);

  }, []);


  return (
    <div className={"Routes"}>
      <AppContext.Provider value={{ isAuth, setIsAuth, data, setData }} >
        <MainRoutes />
      </AppContext.Provider>
    </div>
  );
}

export default App;
