import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import PagoExpress from "./containers/PagoExpress";
import PagoExpressOk from "./containers/PagoExpressOk";
import PagoExpressError from "./containers/PagoExpressError";

export default function MainRoutes() {

  return (
    <div className={"MainRoutes"}>

        <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/pagoexpress/ok/:id">
            <PagoExpressOk />
        </Route>
        <Route exact path="/pagoexpress/ok">
            <PagoExpressOk />
        </Route>
        <Route path="/pagoexpress/error">
            <PagoExpressError />
        </Route>        
        <Route exact path="/pagoexpress/:id">
            <PagoExpress />
        </Route>
        <Route exact path="/pagoexpress">
            <PagoExpress />
        </Route>
        <Route>
                <Home />
        </Route>
        </Switch>

    </div>
  );
}
